/* globals ga, gtag */

// import Darken from '../libs/darken-mod';
import { $, $$, delegate } from './helper/utils';
import outline from './helper/outline';
import inView from './helper/inView';
// import { MOBILMEDIA } from './helper/constants';
import flyingFocus from './components/flyingFocus';
import navigation from './components/navigation';
import langSwitch from './components/langSwitch';

const buttons = $$('a[class*="btn"], button[class*="btn"], input[class*="btn"]');
const loaders = $$('.loader');
const activeFocusTrap = { elements: null };
const sliderMap = new Map();
const sliderDragging = new Map();

for (const btn of buttons) {
  const html = btn.innerHTML.trim();

  if (
    btn.querySelector('span') === null
    || btn.childElementCount > 1
    || (html.startsWith('<span') === false || html.endsWith('/span>') === false)
  ) {
    btn.innerHTML = `<span>${html}</span>`;
  }
}

for (const loader of loaders) {
  const loaderEl = document.createElement('span');
  loaderEl.className = 'loading-indicator';
  loader.appendChild(loaderEl);
  loader.classList.remove('loader');
}

const externalLinks = $$('main a[href^="http"]');
for (const link of externalLinks) {
  if (
    link.className.indexOf('btn') === -1
    && link.querySelector('span') === null
    && link.parentElement.classList.contains('imagebox') === false
  ) {
    const icon = document.createElement('i');
    icon.className = 'icon-arrow-right-up';
    link.appendChild(icon);
  }
}

const params = new URLSearchParams(window.location.search);
if (params.has('darken')) {
  document.documentElement.classList.add('darken');
}

// eslint-disable-next-line no-unused-vars
// const darkmode = new Darken({
//   container: 'html',
//   toggle: '.toggle-darkmode',
// }, (active) => {
//   if (active === false) {
//     document.documentElement.classList.add('lighten');
//   } else {
//     document.documentElement.classList.remove('lighten');
//   }
// });

delegate(document.body, 'click', '[data-gaevent]', function () {
  const gaEvent = this.getAttribute('data-gaevent') || '';
  const placement = this.getAttribute('data-gaplacement') || document.title;
  const type = this.getAttribute('data-gatype') || 'link';

  if ('ga' in window) {
    console.log(gaEvent);
    ga('send', 'event', type, gaEvent, placement, {
      hitCallback() {
        console.log('analytics sent');
      },
    });
  }

  if ('gtag' in window) {
    console.log(`gtag: ${this.dataset.gaevent}_${this.dataset.gatype}-${this.dataset.gaplacement}`);
    gtag('event', this.dataset.gaevent, {
      [this.dataset.gatype]: this.dataset.gaplacement,
    });
  }

  // if ('gtag' in window) {
  //   gtag('event', 'select_content', {
  //     content_type: gaEvent,
  //     item_id: `${type}_${placement}`,
  //   });
  // }
});

delegate(document.body, 'click', '.share-button', function () {
  const placement = this.getAttribute('data-gaplacement') || document.title;

  if ('ga' in window) {
    ga('send', 'event', 'button', `share_${this.dataset.action}`, placement);
  }
});

outline();
navigation(activeFocusTrap);
flyingFocus(activeFocusTrap);
langSwitch();

export default function () {
  if ($('.teaser-fullscreen') !== null) {
    import('./components/teaserFullscreen')
      .catch((err) => {
        // show elements incase of loading error
        const elements = $$('#header, main, #footer');
        for (const el of elements) {
          el.style.opacity = 1;
          el.style.clipPath = 'none';
        }

        console.warn('Chunk teaserFullscreen.js loading failed', err);
      });
  }

  if ($('.show-search') !== null) {
    import('./components/search')
      .then((search) => search.default(activeFocusTrap))
      .catch((err) => {
        console.warn('Chunk search.js loading failed', err);
      });
  }

  if ($('.show-more-text') !== null) {
    import('./components/showMore')
      .then((showMore) => showMore.default(activeFocusTrap))
      .catch((err) => {
        console.warn('Chunk showMore.js loading failed', err);
      });
  }

  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk /forms/select.js loading failed', err);
      });
  }

  if ($('.selectr-select') !== null) {
    import('./components/forms/selectWithSearch')
      .catch((err) => {
        console.warn('Chunk forms/selectWithSearch.js loading failed', err);
      });
  }

  if ($('.form-control, main input') !== null) {
    import('./components/forms/forms')
      .catch((err) => {
        console.warn('Chunk forms/forms.js loading failed', err);
      });
  }

  if (document.body.classList.contains('recipes') === false && $('.filter') !== null) {
    import('./components/forms/filter/filter')
      .catch((err) => {
        console.warn('Chunk forms/filter/filter.js loading failed', err);
      });
  }

  if (document.body.classList.contains('recipes') && $('.filter') !== null) {
    import('./components/forms/filter/recipes')
      .catch((err) => {
        console.warn('Chunk forms/filter/recipes.js loading failed', err);
      });
  }

  if ($('.review-rate-product') !== null) {
    import('./components/forms/rating')
      .catch((err) => {
        console.warn('Chunk /forms/rating.js loading failed', err);
      });
  }

  if ($('.form-input.labeled input') !== null) {
    import('./components/forms/labeled')
      .catch((err) => {
        console.warn('Chunk /forms/labeled.js loading failed', err);
      });
  }

  if ($('.form-upload-wrapper') !== null) {
    import('./components/forms/files')
      .catch((err) => {
        console.warn('Chunk /forms/files.js loading failed', err);
      });
  }

  inView($$('.autocomplete'), () => {
    import('./components/forms/autocomplete')
      .catch((err) => {
        console.warn('Chunk forms/autocomplete.js loading failed', err);
      });
  });

  inView($$('.tooltip'), () => {
    import('./components/tooltip')
      .catch((err) => {
        console.warn('Chunk tooltip.js loading failed', err);
      });
  });

  inView($$('main video:not(.native)'), () => {
    import('./components/videos/video')
      .catch((err) => {
        console.warn('Chunk videos/video.js loading failed', err);
      });
  });

  inView($$('.lightbox'), () => {
    import('./components/lightbox')
      .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  });

  inView($$('#map-canvas'), () => {
    import('./components/locationsMap/')
      .then((locations) => {
        setTimeout(() => {
          locations.default();
        }, 300);
      })
      .catch((err) => {
        console.warn(`Chunk locationsMap/index.js loading failed: ${err}`);
      });
  });

  inView($$('#text-animation-container'), () => {
    import('./components/textAnimation/')
      .catch((err) => {
        console.warn(`Chunk textAnimation/index.js loading failed: ${err}`);
      });
  });

  if ($('.recipe-servings') !== null) {
    import('./components/recipes/recipeServings')
      .catch((err) => {
        console.warn('Chunk recipes/recipeServings.js loading failed', err);
      });
  }

  if ($('.animalpieces-tab-content') !== null) {
    import('./components/knowledge/animalPieces')
      .catch((err) => {
        console.warn('Chunk knowledge/animalPieces.js loading failed', err);
      });
  }

  if ($('.meatpiece-temperature-levels') !== null && $('body[data-pageid]') === null) {
    import('./components/knowledge/temperature')
      .catch((err) => {
        console.warn('Chunk knowledge/temperature.js loading failed', err);
      });
  }

  if ($('.history') !== null && $('body[data-pageid]') === null) {
    import('./components/history')
      .catch((err) => {
        console.warn('Chunk history.js loading failed', err);
      });
  }

  if ($$('.tr-header, .tc-header, .trc-header').length) {
    import('./components/table')
      .catch((err) => {
        console.warn('Chunk table.js loading failed', err);
      });
  }

  if ($('.page-share') !== null) {
    import('./components/share')
      .catch((err) => {
        console.warn('Chunk share.js loading failed', err);
      });
  }

  if ($('.modal-wrapper') !== null) {
    import('./components/modal')
      .catch((err) => {
        console.warn('Chunk modal.js loading failed', err);
      });
  }

  if ($('.faltpackung-lp') !== null) {
    import('./components/landingpages/faltpackung')
      .catch((err) => {
        console.warn(`Chunk landingpages/faltpackung.js loading failed: ${err}`);
      });
  }

  if ($('.faltpack-2024') !== null) {
    import('./components/landingpages/faltpackung')
      .catch((err) => {
        console.warn(`Chunk landingpages/faltpackung.js loading failed: ${err}`);
      });
  }

  if ($('.wuerstli-lp') !== null) {
    import('./components/landingpages/wuerstli')
      .catch((err) => {
        console.warn(`Chunk landingpages/wuerstli.js loading failed: ${err}`);
      });
  }

  if ($('.burgerwelt-lp') !== null) {
    import('./components/landingpages/burgerwelt')
      .catch((err) => {
        console.warn(`Chunk landingpages/burgerwelt.js loading failed: ${err}`);
      });
  }

  if ($('.cashback-lp') !== null) {
    import('./components/landingpages/sousvide/cashback')
      .catch((err) => {
        console.warn(`Chunk landingpages/sousvide/cashback.js loading failed: ${err}`);
      });
  }

  if ($('.burgerwelt-lp-dev') !== null) {
    import('./components/landingpages/burgerweltDev')
      .catch((err) => {
        console.warn(`Chunk landingpages/burgerweltDev.js loading failed: ${err}`);
      });
  }

  if ($('.bbq-lp') !== null) {
    import('./components/landingpages/bbq')
      .catch((err) => {
        console.warn(`Chunk landingpages/bbq.js loading failed: ${err}`);
      });
  }

  if ($('.dev-bbq-lp') !== null) {
    import('./components/landingpages/bbqDev')
      .catch((err) => {
        console.warn(`Chunk landingpages/bbqDev.js loading failed: ${err}`);
      });
  }

  if ($('.sousvide-lp') !== null) {
    import('./components/landingpages/sousvide')
      .catch((err) => {
        console.warn(`Chunk landingpages/sousvide/index.js loading failed: ${err}`);
      });
  }

  if ($('.poster-wall') !== null) {
    import('./components/posterContest')
      .then((posterContest) => posterContest.default())
      .catch((err) => {
        console.warn(`Chunk posterContest loading failed: ${err}`);
      });
  }

  if ($('.christmas-lp22') !== null) {
    import('./components/landingpages/christmas')
      .catch((err) => {
        console.warn(`Chunk posterContest loading failed: ${err}`);
      });
  }

  if ($('.quick-lp') !== null && $('.christmas23-lp') === null) {
    import('./components/landingpages/quick')
      .catch((err) => {
        console.warn(`Chunk quick loading failed: ${err}`);
      });
  }

  // if ($('.burger-challenge.challenge') !== null) {
  //   import('./components/burgerChallenge/challenge')
  //     .catch((err) => {
  //       console.warn(`Chunk burgerChallenge/challenge loading failed: ${err}`);
  //     });
  // }

  if ($('.bc-votings-list') !== null) {
    import('./components/burgerChallenge/votings')
      .catch((err) => {
        console.warn(`Chunk burgerChallenge/votings loading failed: ${err}`);
      });
  }

  if ($('.bc-nav') !== null) {
    import('./components/burgerChallenge/menu')
      .catch((err) => {
        console.warn(`Chunk burgerChallenge/menu loading failed: ${err}`);
      });
  }

  if ($('.bc-burger-tips') !== null) {
    import('./components/burgerChallenge/tips')
      .catch((err) => {
        console.warn(`Chunk burgerChallenge/tips loading failed: ${err}`);
      });
  }

  if ($('.infinite-slider') !== null) {
    import('./components/infiniteSlider')
      .then((infiniteSlider) => infiniteSlider.default(sliderMap))
      .catch((err) => {
        console.warn(`Chunk infiniteSlider loading failed: ${err}`);
      });
  }

  if ($('.overlay-slider') !== null) {
    import('./components/overlaySlider')
      .catch((err) => {
        console.warn(`Chunk overlaySlider loading failed: ${err}`);
      });
  }

  inView($$('.nav-tabs, [data-toggle="collapse"]'), () => {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }

        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  });

  if ($('[data-less]') !== null) {
    const moreLess = $$('[data-less]');

    for (const el of moreLess) {
      const more = el.innerText;
      const { less } = el.dataset;

      el.addEventListener('click', () => {
        if (el.innerText === more) {
          if (el.className.indexOf('btn') !== -1 && el.firstElementChild.nodeName === 'SPAN') {
            el.firstElementChild.textContent = less;
          } else {
            el.textContent = less;
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (el.className.indexOf('btn') !== -1 && el.firstElementChild.nodeName === 'SPAN') {
            el.firstElementChild.textContent = more;
          } else {
            el.textContent = more;
          }
        }
      });
    }
  }

  if ($('[data-scrollto]') !== null) {
    import('./components/smoothScroll')
      .catch((err) => {
        console.warn(`Chunk smoothScroll.js loading failed: ${err}`);
      });
  }

  if ($('[data-dismiss]') !== null) {
    const dismissables = $$('[data-dismiss]');

    for (const dismiss of dismissables) {
      const target = dismiss.closest(`.${dismiss.dataset.dismiss}`);

      if (target !== null) {
        console.log(target);

        dismiss.addEventListener('click', () => {
          target.remove();
        });
      }
    }
  }
}
